import React, { useState, useEffect } from 'react';
import Background1 from '../assets/Background Files/Background 1.png';
import Background2 from '../assets/Background Files/Background 2.png';
import Background3 from '../assets/Background Files/Background 3.png';
import Background4 from '../assets/Background Files/Background 4.mp4';

// Define separate images for mobile and tablet devices
import MobileBackground1 from '../assets/Background Files/Mobile/Background1-mobile.png';
import MobileBackground2 from '../assets/Background Files/Mobile/Background2-mobile.png';
import MobileBackground3 from '../assets/Background Files/Mobile/Background3-mobile.png';
import MobileBackground4 from '../assets/Background Files/Mobile/Background4-mobile.mp4';

import TabletBackground1 from '../assets/Background Files/Tablet/Background1-tablet.png';
import TabletBackground2 from '../assets/Background Files/Tablet/Background2-tablet.png';
import TabletBackground3 from '../assets/Background Files/Tablet/Background3-tablet.png';
import TabletBackground4 from '../assets/Background Files/Tablet/Background4-tablet.mp4'; // Add tablet-specific images/videos

const backgrounds = [
  { type: 'image', src: Background1, mobileSrc: MobileBackground1, tabletSrc: TabletBackground1, duration: 10000 },
  { type: 'image', src: Background2, mobileSrc: MobileBackground2, tabletSrc: TabletBackground2, duration: 10000 },
  { type: 'image', src: Background3, mobileSrc: MobileBackground3, tabletSrc: TabletBackground3, duration: 10000 },
  { type: 'video', src: Background4, mobileSrc: MobileBackground4, tabletSrc: TabletBackground4, duration: 30000 },
];

const Hero = ({ titleSize = 'text-4xl', subtitleSize = 'text-lg' }) => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 480 && window.innerWidth <= 1024); // Change tablet range

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackgroundIndex((prevIndex) =>
        prevIndex === backgrounds.length - 1 ? 0 : prevIndex + 1
      );
    }, backgrounds[currentBackgroundIndex].duration);

    // Check for window resize to toggle between mobile, tablet, and desktop images
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      setIsTablet(window.innerWidth > 480 && window.innerWidth <= 1024); // Adjust to target wider tablets
    };
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    };
  }, [currentBackgroundIndex]);

  const currentBackground = backgrounds[currentBackgroundIndex];

  return (
    <section
      className="relative flex items-center text-white bg-cover bg-center py-20 overflow-hidden"
      style={{ height: '50vh' }}
    >
      <style jsx>{`
        /* Mobile-specific adjustments */
        @media (max-width: 320px) {
          img, video {
            height: 100vh;
            width: 100vw;
            object-fit: cover;
            position: absolute;
          }

          .custom-position-mobile {
            left: -100px;
          }

          .custom-position-mobile-image {
            left: -100px;
          }

          h1 {
            font-size: 2rem;
          }

          p {
            font-size: 1.2rem;
          }
        }

        /* Tablet-specific adjustments */
        @media (min-width: 481px) and (max-width: 320px) {
          img, video {
            height: 100vh;
            width: 100vw;
            object-fit: cover;
            position: absolute;
          }

          .custom-position-tablet {
            left: -200px; /* Adjust this value to move the tablet background */
          }

          .custom-position-tablet-image {
            left: -150px; /* Adjust this value to move the tablet image */
          }

          h1 {
            font-size: 2.5rem; /* Adjust heading size for tablets */
          }

          p {
            font-size: 1.5rem; /* Adjust paragraph size for tablets */
          }
        }

        @media (min-width: 1025px) {
          img, video {
            object-position: center;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      `}</style>

      {/* Background */}
      {currentBackground.type === 'image' ? (
        <img
          className={`absolute inset-0 w-full h-full object-cover ${
            isMobile ? 'custom-position-mobile-image' : isTablet ? 'custom-position-tablet-image' : ''
          }`}
          src={
            isMobile && currentBackground.mobileSrc
              ? currentBackground.mobileSrc
              : isTablet && currentBackground.tabletSrc
              ? currentBackground.tabletSrc
              : currentBackground.src
          }
          alt="Background"
        />
      ) : (
        <video
          className={`absolute inset-0 w-full h-full object-cover ${
            isMobile ? 'custom-position-mobile' : isTablet ? 'custom-position-tablet' : ''
          }`}
          src={
            isMobile && currentBackground.mobileSrc
              ? currentBackground.mobileSrc
              : isTablet && currentBackground.tabletSrc
              ? currentBackground.tabletSrc
              : currentBackground.src
          }
          autoPlay
          muted
          playsInline
        />
      )}

      {/* Overlay for better contrast */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-transparent"></div>

      {/* Content */}
      <div className="relative z-20 max-w-lg px-30">
        <h1 className={`${titleSize} font-bold mb-4`}>Revolutionize Your Life with Hilance</h1>
        <p className={`${subtitleSize} mb-8`}>We build cutting-edge software to help your business thrive.</p>
        <a href="https://www.instagram.com/hilance.app/" target="_blank" rel="noopener noreferrer">
          <button className="bg-[#5A4DD4] hover:bg-[#4C42B0] px-6 py-2 rounded-full text-lg font-semibold text-white">
            Learn More
          </button>
        </a>
      </div>
    </section>
  );
};

export default Hero;
