// src/components/AboutUs.js
import React from 'react';
import AppImage1 from '../assets/app-image1.jpg'; // Ensure path to image is correct
import AppVideo from '../assets/app-image3.mp4'; // Ensure path to video is correct

const AboutUs = () => {
  return (
    <section id="about" className="py-8 bg-gray-50"> {/* Add id for anchor linking */}
      <div className="container mx-auto px-4">
        
        {/* First Section with purple background */}
        <div className="relative flex flex-col md:flex-row items-center mb-8">
          {/* Purple background behind the image */}
          <div className="absolute bg-[#f5f3ff] w-[100%] h-[80%] -left-10 top-10 rounded-xl hidden md:block"></div>

          {/* Image with seamless layout */}
          <div className="relative z-10 md:w-[25%] mb-4 md:mb-0 md:mr-6 p-0 rounded-xl shadow-none">
            <img
              src={AppImage1}
              alt="App Development"
              className="rounded-xl object-cover w-full h-auto"
            />
          </div>

          {/* Text */}
          <div className="relative z-10 md:w-[65%] bg-[#f5f3ff] p-8 rounded-xl shadow-lg">
            <h2 className="text-lg text-[#5A4DD4] font-semibold mb-3">About Us</h2>
            <h3 className="text-3xl font-bold mb-4 leading-tight">Mobile App Development Experts</h3>
            <p className="text-gray-600 leading-relaxed text-base mb-10">
              Hilance is a leading mobile application development company that specializes in 
              creating high-quality apps for both iOS and Android platforms, as well as web 
              applications. Our team of skilled developers can build custom mobile apps that 
              can be sold as white-label solutions or published on the App Store and Google 
              Play for subscription-based models.
            </p>
          </div>
        </div>

        {/* Second Section */}
        <div className="flex flex-col md:flex-row items-center">
          {/* Text */}
          <div className="md:w-[65%] mb-4 md:mb-0 md:mr-4">
            <h2 className="text-lg text-[#5A4DD4] font-semibold mb-3">The Path to Mobile App Excellence</h2>
            <h3 className="text-3xl font-bold mb-4 leading-tight">Delivering Tailored Mobile App Solutions</h3>
            <p className="text-gray-600 leading-relaxed text-base mb-4">
              At Hilance, we are dedicated to delivering high-performance mobile applications tailored to your business needs. 
              Our expertise spans across iOS and Android platforms, offering end-to-end solutions for app development—from concept 
              and design to launch and support.
            </p>
          </div>

          {/* Video with seamless layout */}
          <div className="md:w-[40%] p-0 rounded-xl shadow-none">
            <video
              src={AppVideo}
              alt="Mobile Health App"
              className="rounded-xl object-cover w-full h-auto"
              autoPlay
              loop
              muted
              playsInline
            ></video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
