// src/components/Contact.js
import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    source: 'Hilance Website'  // Adding the source field
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://roboti-landing-page-production.up.railway.app/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', phone: '', message: '', source: 'Hilance' }); // Reset form but keep the source
        setTimeout(() => setStatus(''), 3000);
      } else {
        setStatus('Error sending message');
      }
    } catch {
      setStatus('Error sending message');
    }
  };

  return (
    <section id="contact" className="py-12 bg-gray-50"> {/* Add id for anchor linking */}
      <div className="max-w-6xl mx-auto px-4 md:px-0 grid grid-cols-1 md:grid-cols-2 gap-8">
        
        {/* Contact Us Information */}
        <div className="bg-gray-100 p-8 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-[#5A4DD4] mb-3">Contact Us</h3>
          <h2 className="text-3xl font-bold mb-4">Better yet, see us in person!</h2>
          <p className="text-gray-700 mb-6">
            Have a question or a story to share? Send us a message and we’ll get back to you soon.
          </p>
          <div>
            <h4 className="font-bold">Hilance</h4>
            <div className="flex items-center mb-2">
              <p className="text-gray-700 font-semibold mr-2">Hours</p>
              <span className="text-gray-600 text-xs">▼</span>
            </div>
            <ul className="text-gray-700">
              <li>Mon 09:00 am – 05:00 pm</li>
              <li>Tue 09:00 am – 05:00 pm</li>
              <li>Wed 09:00 am – 05:00 pm</li>
              <li>Thu 09:00 am – 05:00 pm</li>
              <li>Fri Closed</li>
              <li>Sat 09:00 am – 05:00 pm</li>
              <li>Sun 09:00 am – 05:00 pm</li>
            </ul>
          </div>
        </div>

        {/* Contact Form */}
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Drop us a Line</h3>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="border p-3 rounded-lg w-full"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="border p-3 rounded-lg w-full"
                required
              />
            </div>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your Phone"
              className="border p-3 rounded-lg w-full"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              className="border p-3 rounded-lg w-full"
              rows="4"
              required
            ></textarea>

            {/* Hidden input to specify the source */}
            <input type="hidden" name="source" value="Hilance" />

            <p className="text-sm text-gray-500">
              This site is protected by reCAPTCHA and the Google {" "}
              <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-[#5A4DD4] underline">Privacy Policy</a> {" "}
              and {" "}
              <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer" className="text-[#5A4DD4] underline">Terms of Service</a> {" "}
              apply.
            </p>
            <button
              type="submit"
              className="bg-[#5A4DD4] text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-[#4C42B0] focus:outline-none focus:ring-2 focus:ring-[#5A4DD4]"
            >
              Send Message
            </button>
          </form>
          {status && <p className="mt-4 text-lg text-green-500">{status}</p>}
        </div>
      </div>
    </section>
  );
}

export default Contact;
