// src/components/Subscription.js
import React, { useState } from 'react';
import subscriptionBackground from '../assets/robots1.png'; // Adjust path if necessary
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import Firebase config

function Subscription({ source }) {  // Add a source prop to determine the website source
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setStatus('Please enter a valid email address');
      return;
    }

    try {
      // Save the email to Firebase Firestore
      await addDoc(collection(db, 'subscribers'), {
        email,
        subscribedAt: new Date(),
        source: source || 'Hilance',
      });

      // Send a confirmation email (if your backend handles that)
      const response = await fetch('https://roboti-landing-page-production.up.railway.app/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      if (response.status === 200) {
        setStatus('Subscription successful! Check your email for confirmation.');
        setEmail(''); // Clear the input field
      } else {
        setStatus(result.message || 'Something went wrong, please try again.');
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setStatus('Error subscribing. Please try again later.');
    }
  };

  return (
    <section
      id="subscribe" // Added id="subscribe" for anchor link navigation
      className="relative text-white py-12 sm:py-16 bg-cover bg-center"
      style={{
        backgroundImage: `url(${subscriptionBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '290px', // Ensures a minimum height
      }}
    >
      {/* Transparent Overlay */}
      <div className="absolute inset-0" style={{ backgroundColor: 'rgba(90, 77, 212, 0.6)' }}></div>

      <div className="relative z-10 container mx-auto text-center px-4">
        <h2 className="text-2xl sm:text-3xl font-bold mb-2">Subscribe</h2>
        <p className="text-md sm:text-lg mb-4">Sign up to hear from us about specials, sales, and events.</p>
        <form className="flex justify-center flex-wrap gap-4" onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address..."
            className="p-3 rounded-full w-[18rem] sm:w-[28rem] text-gray-800 focus:outline-none"
            required
          />
          <button
            type="submit"
            className="bg-[#5A4DD4] text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-[#4C42B0] focus:outline-none focus:ring-2 focus:ring-[#5A4DD4]"
          >
            Subscribe
          </button>
        </form>
        {status && <p className="mt-4 text-white">{status}</p>}
      </div>
    </section>
  );
}

export default Subscription;
