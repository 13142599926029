import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'; // If you have any global styles
import App from './App';
import reportWebVitals from './reportWebVitals'; // Optional if you want to track performance

// Create the root container for React
const container = document.getElementById('root');

// Create a React root
const root = createRoot(container);

// Render your app
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to measure performance in your app
reportWebVitals();
