import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Subscription from './components/Subscription';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App h-screen overflow-x-hidden overflow-y-auto scroll-container">
      <Header />
      <Hero />
      <AboutUs />        {/* About Us component */}
      <Contact />
      <Subscription />
      <Footer />
    </div>
  );
}

export default App;
